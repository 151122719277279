// Step2.js

import React, { useState } from "react";

function Step2({onSubmit, inputObj}) {

  // State to track visibility of each card content
  const [visibleCards, setVisibleCards] = useState(
    Object.fromEntries(Object.keys(inputObj).map((key) => [key, true]))
  );

  // Toggle visibility function
  const toggleVisibility = (key) => {
    setVisibleCards((prevVisibleCards) => ({
      ...prevVisibleCards,
      [key]: !prevVisibleCards[key] // Toggle the visibility
    }));
  };
  

  return (
    <form onSubmit={onSubmit}>
      <div>
        {Object.entries(inputObj).map(([key, value]) => (
          <div key={key} className="card">
            <header className="card-header">
              <h3 className="card-header-title" style={{ marginBottom: "8px" }}>{key}</h3>
              <button
                type="button"
                className="card-header-icon"
                aria-label="toggle visibility"
                onClick={() => toggleVisibility(key)}
              >
                <span className="icon">
                  <i
                    className={`fas ${visibleCards[key] ? "fa-angle-up" : "fa-angle-down"}`}
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            {visibleCards[key] && (
              <div className="card-content">
                <div className="content">
                  <h3 className="body">{value.description}</h3>
                  <b className="body">Legal element checker</b>

                  {value.elements.map((element, index) => (
                    <div key={index}>
                      <b className="body" style={{ fontWeight: "600" }}>
                        {element.name} - {element.source}
                      </b>
                      <span>
                        {element.satisfied ? (
                          <button className="button is-primary is-small">Met</button>
                        ) : (
                          <button className="button is-danger is-small">Not met</button>
                        )}
                      </span>
                      {!element.satisfied && element.analysis && (
                        <div style={{ marginTop: "5px", color: "red" }}>
                          {element.analysis.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next Step: Recomendations</button>
        </div>
      </form>
  );
}

export default Step2;
