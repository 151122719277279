// Step1.js

import React from 'react';

function Step1({onSubmit, coas, fas}) {

  return (
    <div>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Summary</h1>
      </div>
      <form onSubmit={onSubmit}>

        <div className="box is-draft">
          <h3 className="title is-5">Causes of Action</h3>
          {coas.map((item, index) => (
                  <div key={index} className="box is-draft">
                    <b className='body' style={{fontWeight: "600" }}>{item}</b>
                  </div>
          ))}
        </div>

        <div className="box is-draft">
          <h3 className="title is-5">Factual Allegations</h3>
          {fas.map((item, index) => (
                  <div key={index} className="box is-draft">
                    <b className='body' style={{fontWeight: "600" }}>{item}</b>
                  </div>
          ))}
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next Step: Analysis</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
