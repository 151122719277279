// Step3.js

import React, { useState } from 'react';


function Step3({onSubmit, recomendations}) {
  const [selectedValues, setSelectedValues] = useState({});

  return (
    <div>
      <div class="block">
        <h1 className="title is-4"  style={{ marginBottom: '8px' }}>Recomendations</h1>
      </div>
      <form onSubmit={onSubmit}>
        {/* /* <div className="box is-draft" name="recomendations" id="recomendations" defaultValue={recomendations}>{recomendations} */ }
        <div className='block'>
        <textarea className="box is-draft" name="recomendations" id="recomendations" defaultValue={recomendations} rows="20" cols="60"></textarea>
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next Step: Download</button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
